import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailSignup from "../components/emailSignup"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content">
        {/* page-template no-image */}
        {/* <div className="page-head post-content-body"> */}
        <header className="post-content-header">
          <h1 className="post-content-title">Hello there! My name is Jing.</h1>
        </header>

        <p class="post-content-excerpt">
          Like many others, I take photos to remember and share stories.
        </p>
        <figure className="kg-card kg-image-card ">
          <Img
            fluid={data.profilepic.childImageSharp.fluid}
            className="kg-image"
          />
        </figure>
        <p class="post-content-body">
          When creating this website, I sought a way to seamlessly interleave
          photos within a story. On Instagram, Flickr, and the like, it's{" "}
          <em>only</em> about photos; no one would read a 1200-word caption. But
          I also didn't want to create a traditional travel blog with "Essential
          Guides" and "Top 20 Things to Do!!". My favorite travel blogs are the
          ones that simply tell a story, and that's what I strive to do here: I
          want to share my photos in a way that respects their context. But of
          course, in true travel blog spirit, I still include a bunch of
          practical tips at the end of each post.
        </p>
        <p class="post-content-body">
          I hope that my stories will inspire you to continue your own
          adventures.
        </p>
        <h5>Who am I?</h5>
        <p class="post-content-body">
          I'm a student in computer science and hopeful future software engineer
          who's a creative at heart. Throughout the years I've composed and
          played classical music; now, photography and writing are my main
          creative outlets.
        </p>
        <h5>What's in a name?</h5>
        <p class="post-content-body">
          Track & Tower, as you may have seen if you came to this page via
          Google, is a trail in Algonquin Provincial Park in Ontario. I spent
          many summers there as a kid, and Algonquin is where I found a passion
          for hiking, camping, and photography. I've hiked almost every trail
          there, and while I only have hazy memories of Track & Tower, it
          definitely makes for the best blog name out of all of them.
        </p>
        <h5>What do I shoot with?</h5>
        <p class="post-content-body">
          I use a Fujifilm X-T20 with two lenses, the kit 18-55mm f/2.8-4 and
          the Rokinon 12mm f/2.0.
        </p>
        <h5>How can you contact me?</h5>
        <p class="post-content-body">
          I'll set up a form later, but for now just DM me (no, not like that)
          on{" "}
          <a
            href="https://www.instagram.com/odpixels/"
            title="Instagram Icon"
            target="_blank"
          >
            Instagram
          </a>
          , and don't forget to follow!
        </p>
        {/* </div> */}
      </article>
      <EmailSignup />
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    profilepic: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
